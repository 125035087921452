import * as React from 'react'
import { graphql, Link, PageProps } from 'gatsby'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import { Button } from 'gatsby-theme-material-ui'
import { GatsbyImage } from 'gatsby-plugin-image'
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined'
import { Paper } from '@mui/material'
import SEO from '../components/SEO/SEO'
import Layout from '../components/Layout/Layout'
import dotGrid from '../images/icons/dot_grid.svg'
import rLogo from '../images/icons/small_linear_r.svg'
import topQuote from '../images/icons/top_quote.svg'
import bottomQuote from '../images/icons/bottom_quote.svg'
import GatsbyLinkCardAction from '../components/GatsbyLinkCardAction'
import TitleHeader from '../components/TitleHeader/TitleHeader'
import useMediaQueries from '../hooks/useMediaQueries'
import ContactForm from '../components/ContactForm/ContactForm'
import { sectionHeadingStyles } from '../components/CarouselBlock/CarouselBlock'

// eslint-disable-next-line no-undef
const IndexPage = ({ data }: PageProps<Queries.IndexPageQuery>) => {
  const { isSmall, theme } = useMediaQueries()

  return (
    <>
      <SEO
        description='Web application, writing, and general programming services to commercial and non-commercial businesses.'
        title='About'
      />
      <Layout image={data?.sanityHomePage?.logo?.asset || undefined}>
        <Box
          sx={{
            '&::before': {
              content: '""',
              backgroundImage: `url('${dotGrid}')`,
              height: isSmall ? '0px' : '520px',
              width: '520px',
              position: 'absolute',
              top: 0,
              left: '-468px',
            },
          }}
          maxWidth='1400px'
          position='relative'
          m='auto'
          pb='142px'
          mb='60px'
          textAlign={isSmall ? 'center' : 'unset'}
        >
          <Box
            width={isSmall ? '100vw' : '90vw'}
            height={isSmall ? '100vw' : '90vw'}
            maxWidth={isSmall ? 'unset' : '460px'}
            maxHeight='460px'
            borderRadius={isSmall ? '0' : '50%'}
            marginRight={isSmall ? 0 : 3}
            position='absolute'
            top='0px'
            right='0px'
            zIndex={-1}
            mt={isSmall ? '150px' : '0px'}
            sx={{
              background:
                '-webkit-linear-gradient(214deg, rgba(3, 191, 203, 0.57) -14.41%, rgba(30, 73, 123, 0.57) 114.41%), #03BFCB',
              boxShadow: `0px 0px 0px ${
                isSmall ? '8px' : '20px'
              } rgba(3, 191, 203, 0.2)`,
            }}
          />
          {data?.sanityAuthor?.image?.asset && (
            <GatsbyImage
              loading='eager'
              style={{
                width: isSmall ? '100vw' : '90vw',
                height: isSmall ? '100vw' : '90vw',
                maxWidth: '460px',
                maxHeight: '460px',
                right: isSmall ? 0 : '24px',
                borderRadius: isSmall ? '0' : '50%',
                position: isSmall ? 'inherit' : 'absolute',
                marginTop: isSmall ? '150px' : '0px',
                top: 0,
                filter: isSmall
                  ? `drop-shadow(2px 4px 6px ${theme.palette.primary.main})`
                  : 'none',
              }}
              image={data.sanityAuthor.image.asset.gatsbyImageData}
              alt='me'
            />
          )}
          <TitleHeader subtitle='Web application developer' />
          <Typography
            fontSize={isSmall ? '16px' : '21px'}
            fontWeight={400}
            lineHeight='1.75'
            letterSpacing='0px'
            width={isSmall ? 'unset' : 475}
            textAlign='center'
            px={isSmall ? '30px' : '0px'}
            ml={isSmall ? '0px' : '100px'}
            mt='38px'
          >
            Start working with an experienced developer that has great
            communcation skills and a proven record of success working in teams.
          </Typography>
          <Button
            variant='contained'
            color='primary'
            to='/services/Web%20Development'
            LinkComponent={Link}
            sx={{
              marginTop: '40px',
              marginLeft: isSmall ? '0px' : '200px',
              px: 3,
              py: 1,
            }}
          >
            Web Development
          </Button>
        </Box>
        <Box maxWidth='1400px' m='auto'>
          <Grid container py='50px' rowSpacing='48px'>
            <Grid item xs={12} lg={4}>
              <Box ml='30px'>
                <Typography
                  fontSize={isSmall ? '35px' : '60px'}
                  lineHeight='1.15'
                  fontWeight={700}
                  color='primary'
                  textTransform='uppercase'
                >
                  DEV
                </Typography>
                <Typography
                  fontSize={isSmall ? '35px' : '60px'}
                  lineHeight='1.15'
                  fontWeight={300}
                  color='secondary'
                  textTransform='uppercase'
                >
                  Experience
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              lg={7}
              display='flex'
              flexWrap='wrap'
              margin='0px 32px'
              justifyContent={isSmall ? 'flex-start' : 'flex-end'}
              gap={isSmall ? 1 : 2}
            >
              {data?.allSanityClient?.nodes &&
                data?.allSanityClient?.nodes.length > 0 &&
                data?.allSanityClient?.nodes.map(edge => {
                  if (edge.mainImage?.asset) {
                    return (
                      <Card
                        sx={{
                          borderRadius: '16px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          background: '#FFFFFF',
                          boxShadow: '8.5px 9.8px 18px rgba(0, 0, 0, 0.15)',
                          height: isSmall ? '95px' : '200px',
                          width: isSmall ? '95px' : '200px',
                        }}
                      >
                        <CardContent
                          sx={{
                            display: 'flex',
                            p: 2,
                            '&:last-child': { pb: 2 },
                          }}
                        >
                          <GatsbyImage
                            image={edge.mainImage?.asset?.gatsbyImageData}
                            alt={edge?.title || ''}
                          />
                        </CardContent>
                      </Card>
                    )
                  }
                  return null
                })}
            </Grid>
          </Grid>
        </Box>
        <Box maxWidth='1400px' m='auto' pt='200px' pb='50px' textAlign='center'>
          <Typography
            display='inline-flex'
            padding='0px 64px'
            fontSize={isSmall ? '35px' : '60px'}
            lineHeight='1.15'
            fontWeight={700}
            color='primary'
            textTransform='uppercase'
            borderBottom={`4px solid ${theme.palette.secondary.main}`}
          >
            About
          </Typography>
          <Box pt={2} px={isSmall ? 2 : '30px'}>
            <Grid container justifyContent='center'>
              <Grid item xs={12} md={10}>
                <Typography
                  p={isSmall ? 1 : 3}
                  color='#4B4B4B'
                  fontSize={isSmall ? '16px' : '24px'}
                  lineHeight='1.67'
                  fontWeight={400}
                >
                  I am a professional web application developer and blogger in
                  Spokane, WA. I graduated from Eastern Washington University
                  with a degree in Business Administration and worked as an
                  Assistant Golf Professional in Washington and California
                  before switching to a career in technology. My area of
                  expertise is front-end development. <br />
                  <br /> I&apos;ve worked successfully with clients from the
                  East Coast of the U.S., Europe, and halfway around the world
                  in India. My articles have been published on multiple blogs
                  covering backend development, API testing, continuous
                  integration, and user interfaces.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box maxWidth='1400px' m='auto' py='200px'>
          <Grid container justifyContent='center'>
            <Grid item xs={12}>
              <Box ml='30px'>
                <Typography
                  fontSize={isSmall ? '35px' : '60px'}
                  lineHeight='1.15'
                  fontWeight={700}
                  color='primary'
                  textTransform='uppercase'
                >
                  CLIENT
                </Typography>
                <Typography
                  fontSize={isSmall ? '35px' : '60px'}
                  lineHeight='1.15'
                  fontWeight={300}
                  color='secondary'
                  textTransform='uppercase'
                >
                  TESTIMONIALS
                </Typography>
              </Box>
            </Grid>
            {data?.allSanityClient?.nodes &&
              data?.allSanityClient?.nodes.length > 0 &&
              data?.allSanityClient?.nodes
                .slice(0, 3)
                .filter(item => item.quote !== null)
                .map(edge => (
                  <Grid item xs={12} md={10} lg={6}>
                    <Box
                      maxWidth='1400px'
                      m='auto'
                      textAlign='center'
                      px={isSmall ? 2 : '75px'}
                      pt={isSmall ? 4 : '50px'}
                      position='relative'
                    >
                      <img
                        src={topQuote}
                        style={{
                          width: '10%',
                          display: 'flex',
                          paddingBottom: '5%',
                        }}
                        alt=''
                      />

                      <Typography
                        fontSize={isSmall ? '16px' : '24px'}
                        fontWeight={500}
                        lineHeight='1.5'
                        letter-Spacing='0px'
                        textAlign='center'
                        color='primary'
                        px={isSmall ? 1 : 5}
                      >
                        {edge.quote}
                      </Typography>

                      <img
                        src={bottomQuote}
                        style={{
                          width: '100%',
                        }}
                        alt=''
                      />
                    </Box>
                  </Grid>
                ))}
            <Grid
              container
              columnSpacing={2}
              rowSpacing={2}
              justifyContent='center'
              py={isSmall ? 5 : '75px'}
            >
              <Grid
                item
                sm={10}
                md={5}
                display='flex'
                alignItems='center'
                justifyContent='flex-end'
              >
                {!isSmall && (
                  <Typography
                    color='primary'
                    sx={{ color: 'rgb(30,73,123)', px: isSmall ? 2 : 0 }}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...sectionHeadingStyles(isSmall)}
                  >
                    Start the conversation
                  </Typography>
                )}
              </Grid>
              <Grid item sm={10} md={5}>
                <Paper
                  elevation={3}
                  sx={{
                    p: 4,
                    boxShadow:
                      '0px 3px 2px 0px rgb(30,73,123), 0px 3px 9px 4px rgb(3,191,203)',
                  }}
                >
                  <Typography
                    color='primary'
                    pb={2}
                    variant='body2'
                    sx={{ color: 'rgb(30,73,123)' }}
                  >
                    {isSmall ? 'Start the conversation' : 'Contact'}
                  </Typography>
                  <ContactForm paddingTop={false} />
                </Paper>
              </Grid>
            </Grid>
            {data?.allSanityClient?.nodes &&
              data?.allSanityClient?.nodes.length > 0 &&
              data?.allSanityClient?.nodes
                .slice(3)
                .filter(item => item.quote !== null)
                .map(edge => (
                  <Grid item xs={12} md={10} lg={6}>
                    <Box
                      maxWidth='1400px'
                      m='auto'
                      textAlign='center'
                      px={isSmall ? 2 : '75px'}
                      pt={isSmall ? 4 : '50px'}
                      position='relative'
                    >
                      <img
                        src={topQuote}
                        style={{
                          width: '10%',
                          display: 'flex',
                          paddingBottom: '5%',
                        }}
                        alt=''
                      />

                      <Typography
                        fontSize={isSmall ? '16px' : '24px'}
                        fontWeight={500}
                        lineHeight='1.5'
                        letter-Spacing='0px'
                        textAlign='center'
                        color='primary'
                        px={isSmall ? 1 : 5}
                      >
                        {edge.quote}
                      </Typography>

                      <img
                        src={bottomQuote}
                        style={{
                          width: '100%',
                        }}
                        alt=''
                      />
                    </Box>
                  </Grid>
                ))}
          </Grid>
        </Box>
        <Box
          sx={{
            background:
              '-webkit-linear-gradient( -34deg, rgb(3,191,203) 0%, rgb(30,73,123) 100%)',
          }}
        >
          <Box
            maxWidth='1400px'
            m='auto'
            py='10vh'
            px='30px'
            position='relative'
            zIndex={0}
          >
            <img
              alt='logo'
              src={rLogo}
              style={{
                width: '25vw',
                position: 'absolute',
                left: '-10vw',
                zIndex: -1,
                bottom: '5vh',
              }}
            />
            <Box flexWrap='wrap' display='flex' justifyContent='space-between'>
              <Box>
                <Typography
                  fontSize={isSmall ? '28px' : '70px'}
                  fontWeight={700}
                  lineHeight='1.05'
                  textTransform='uppercase'
                  color='white'
                >
                  Blog
                </Typography>
                <Typography
                  fontSize={isSmall ? '28px' : '70px'}
                  lineHeight='1.05'
                  fontWeight={300}
                  textTransform='uppercase'
                  color='white'
                >
                  Recommendations
                </Typography>
              </Box>
              <Button
                LinkComponent={Link}
                to='/blog'
                endIcon={
                  <ArrowCircleRightOutlinedIcon
                    sx={{
                      color: 'white',
                      fontSize: '24px',
                    }}
                  />
                }
                sx={{
                  border: 'none',
                  alignSelf: 'flex-end',
                  fontSize: '21px',
                  fontWeight: 700,
                  color: 'white',
                  '&:hover': {
                    textDecoration: 'underline',
                    textDecorationColor: theme.palette.secondary.main,
                  },
                  pl: 0,
                }}
                variant='text'
              >
                See the Full Blog
              </Button>
            </Box>
            <Box pt='5vh'>
              <Grid container rowSpacing={3} columnSpacing={3}>
                {data?.allSanityPost?.nodes &&
                  data.allSanityPost.nodes.length > 0 &&
                  data?.allSanityPost?.nodes.map(postEdge => (
                    <Grid item xs={12} md={4} key={postEdge.id}>
                      <Card>
                        <GatsbyLinkCardAction
                          disabled={false}
                          sx={{ zIndex: 1, padding: '40px 8px' }}
                          to={`/blog/${postEdge.slug?.current}`}
                        >
                          <CardMedia sx={{ textAlign: 'center', p: 2 }}>
                            {postEdge.mainImage?.asset && (
                              <GatsbyImage
                                image={
                                  postEdge.mainImage?.asset?.gatsbyImageData
                                }
                                alt=''
                              />
                            )}
                          </CardMedia>
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant='h5'
                              component='div'
                              textAlign='center'
                              color='#265790'
                              textTransform='uppercase'
                              fontWeight={700}
                            >
                              {postEdge.title}
                            </Typography>
                            <Typography
                              variant='body1'
                              color='text.secondary'
                              textAlign='center'
                            >
                              {postEdge.excerpt}
                            </Typography>
                          </CardContent>
                        </GatsbyLinkCardAction>
                      </Card>
                    </Grid>
                  ))}
              </Grid>
            </Box>
          </Box>
        </Box>
        <Box maxWidth='1400px' m='auto' textAlign='center'>
          <Card
            sx={{
              background: '#FFFFFF',
              margin: 'auto',
              boxShadow: '8.5px 9.8px 18px rgba(0, 0, 0, 0.15)',
              borderRadius: '16px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              padding: '5%',
              marginBottom: '15vh',
              marginTop: '15vh',
              marginLeft: '5%',
              marginRight: '5%',
            }}
          >
            <CardContent
              sx={{
                fontSize: isSmall ? '26px' : '40px',
                fontWeight: 500,
                lineHeight: '1.4',
                letterSpacing: '0px',
                textAlign: 'center',
                color: '#1E497B',
              }}
            >
              “Products or companies that bear the owner&apos;s name convey very
              valuable messages. They are shouting that they have something to
              lose.”
            </CardContent>
            <Box
              paddingTop='40px'
              paddingBottom='20px'
              width='15%'
              maxWidth='128px'
              borderBottom={`5px solid ${theme.palette.secondary.main}`}
            />
            <Box
              display='flex'
              flexDirection={isSmall ? 'column' : 'row'}
              alignContent='center'
              paddingTop={isSmall ? '15px' : '30px'}
            >
              <Typography
                fontSize={isSmall ? '18px' : '24px'}
                fontWeight={900}
                textTransform='uppercase'
                color='#1E497B'
              >
                Nassim Nicholas Taleb,
              </Typography>
              <Typography
                fontSize={isSmall ? '18px' : '24px'}
                textTransform='capitalize'
                fontWeight={500}
                color='#1E497B'
              >
                &nbsp;Skin in the Game
              </Typography>
            </Box>
          </Card>
        </Box>
      </Layout>
    </>
  )
}

export default IndexPage

export const query = graphql`
  query IndexPage {
    site {
      siteMetadata {
        title
      }
    }
    sanityHomePage {
      logo {
        asset {
          gatsbyImageData(width: 306, height: 100, placeholder: BLURRED)
        }
      }
    }
    sanityAuthor(name: { eq: "Jarrett Retz" }) {
      image {
        asset {
          gatsbyImageData(placeholder: BLURRED, aspectRatio: 1)
        }
      }
    }
    allSanityClient(sort: { fields: _createdAt, order: DESC }) {
      nodes {
        title
        link
        _id
        canUse
        quote
        source
        mainImage {
          asset {
            gatsbyImageData(placeholder: BLURRED, fit: MAX)
          }
        }
      }
    }
    allSanityPost(
      limit: 3
      filter: { categories: { elemMatch: { title: { eq: "tech" } } } }
      sort: { fields: publishedAt, order: DESC }
    ) {
      nodes {
        id
        series {
          title
          _id
        }
        mainImage {
          asset {
            gatsbyImageData(
              width: 485
              height: 160
              fit: MAX
              placeholder: BLURRED
            )
          }
        }
        publishedAt(formatString: "MMMM Do, YYYY")
        title
        slug {
          current
        }
        excerpt
      }
    }
  }
`
